#root {
  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
    #txt-filename {
      width: 200px;
    }
    input {
      margin-right: 12px;
    }
    label {
      margin-right: 8px;
    }
    .ant-select {
      margin-right: 12px;
    }
  }
}