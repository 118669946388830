#root {
  #page-login {
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 120px;
      margin-bottom: 36px;
    }
  }
}