@import '~antd/dist/antd.css';
.App {
  text-align: left;
}

body, html {
  font-family: "Arial", sans-serif;
}

.loading-wrap {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loading-wrap-sm {
  height: 387px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
} */

.ant-layout-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

ul.ant-menu-horizontal {
  flex: 1;
  margin-left: 24px;
}

.ant-layout.site-layout {
  /* border: solid red 1px; */
  padding-top: 16px;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo {
  /* border: solid red 1px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 16px 0 32px; */
  height: 80px;

}

.logo img {
  /* float: left; */
  /* width: 120px; */
  height: 32px;
  /* border: solid red 1px; */
  /* margin: 16px 24px 16px 0; */
}

.logo div {
  /* border: solid red 1px; */
  color: #fffa;
  padding-left: 8px;
  font-size: 15px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.bg1 {
  background-color: yellow;
}

.bg2 {
  background-color: yellowgreen;
}

.bg3 {
  background-color: oldlace;
}

.bg-white {
  background-color: #fff;
  padding: 16px;
  margin-bottom: 24px;
}

.elem-page {
  min-height: 85vh;
}

.ant-layout-footer {
  padding: 0 0 16px;
}

.ant-layout-sider {
  background: #303033;
  }

.ant-menu.ant-menu-dark {
  /* color: rgba(255, 255, 255, 0.65); */
  background: #303033;
  }

  .ant-layout-sider-trigger {
    background: #38383a;
    }

    .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
      background-color: #2a2a2b;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #518c34;
      }