.orgchart.gcChart {
  background-image: linear-gradient(
      90deg,
      rgba(33, 90, 136, 0.15) 10%,
      rgba(0, 0, 0, 0) 10%
    ),
    linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;
}

.orgchart.gcChart > ul > li > ul li::before {
  border-top-color: #2f6d71;
}

.orgchart.gcChart > ul > li > ul li .oc-node::before,
.orgchart.gcChart ul li .oc-node:not(:only-child)::after {
  background-color: #2f6d71;
}

.orgchart.gcChart .oc-node .designation {
  box-sizing: border-box;
  background-color: #2f6d71;
  color: #fff;
  width: 130px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orgchart.gcChart .oc-node .fullname {
  box-sizing: border-box;
  color: #2f6d71;
  background-color: #fff;
  width: 130px;
  height: 65px;
  border: 1px solid #2f6d71;
  display: flex;
  align-items: center;
  justify-content: center;
}
