#root #page-people {
  .ant-card {
    margin-bottom: 16px;
  }
  .slide-main {
    height: 480px;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
  }
  .slide-item {
    height: 236px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    .slide-caption {
      p {
        margin-bottom: 0px;
      }
    }
  }
  .slide-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000a;
    padding: 16px 16px 16px;
    text-align: left;
    h3 {
      color: #fff;
    }
    p {
      color: #fffa;
      margin-bottom: 8px;
    }
    a {
      color: #fffc;
      text-decoration: underline;
    }
  }
  .spotlight-item {
    text-align: center;
    h4 {
      margin-bottom: 4px;
    }
    p:nth-child(2) {
      color: #666;
      margin-bottom: 2px;
    }
    p:nth-child(3) {
      color: #aaa;
    }
  }

  .node {
    border: solid #aaa 1px;
    p {
      margin-bottom: 5px;
      color: #777;
    }
  }
}