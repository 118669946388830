#root .page-services {
  .ant-breadcrumb {
    font-size: 18px;
    font-weight: 600;
    line-height: unset;
    color: #7b8492;
    li:last-child {
      color: #333a46;
      font-weight: 600;
    }
  }
  .slide-item {
    height: 236px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    .slide-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000a;
      padding: 16px 16px 16px;
      text-align: left;
      transition: all 0.3s ease-in-out;
      h3 {
        color: #fff;
      }
      p {
        color: #fffa;
      }
      a {
        color: #fffc;
        text-decoration: underline;
      }
    }
    &:hover > .slide-caption {
      padding-bottom: 32px;
    }
  }
}