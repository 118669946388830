#root {
  #widget-socialwall {
    h2 {
      font-size: 16px;
      font-weight: 700;
    }
    .ant-col {
      padding: 4px 8px;
      .ant-card {
        background-color: #f7f8fb;
        border: 0 none;
        height: 100%;
        position: relative;
        h3 {
          color: #333;
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
        }
        .blog-wrap {
          .blog-title {
            height: 120px;
            width: 100%;
          }
          h3 {
            margin-top: 8px;
          }
          a {
            color: #88888a;
            text-decoration: underline;
          }
        }
        .twitter-wrap {
          margin-bottom: 12px;
          h3 {
            margin-bottom: 0;
          }
          p {
            margin-bottom: 0;
          }
          .twitter-icon {
            position: absolute;
            bottom: 20px;
            right: 20px;
          }
        }
      }
    }
  }
}