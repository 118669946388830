#root #page-home {
    .slide-wrap {
      position: relative;
      height: 480px;
      width: 100%;
      margin-bottom: 16px;
      // border: solid red 1px;
      .slide-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000a;
        padding: 16px 16px 32px;
        text-align: left;
        h3 {
          color: #fff
        }
        p {
          color: #fffa
        }
        a {
          color: #fffc;
          text-decoration: underline;
        }
      }
    }
    .flags-wrap {
      padding-top: 24px;
      .flag-wrap {
        width: 112px;
        margin-bottom: 16px;
        img {
          height: 20px;
          width: auto;
          margin-right: 5px;
        }
      }
      .ant-col {
        display: flex;
        align-items: center;
      }
    }
    .links-wrap {
      margin-top: 16px;
      .ant-btn {
        margin-bottom: 12px;
        margin-right: 12px;
      }
    }
    .holiday {
      background-color: #bbbbbf;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      // height: 60px;
      padding-top: 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        font-size: 18px;
        margin-bottom: 0px;
        text-align: center;
        color: #fff;
        font-weight: 600;
      }
      p {
        font-size: 12px;
        margin-bottom: 0;
        color: #fff;
        // padding: 3px 0;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .ant-carousel .slick-dots {
      left: auto;
      margin-right: 16px;
      }
    .ant-card {
      margin-bottom: 16px;
    }
    .links-wrap ul {
      list-style-type: none;
      padding-left: 0;
    }
    .social-wrap {
      img {
        width: 100%;
      }
    }
}